@use 'helpers/variables' as v;
@use 'helpers/mixins' as m;

.about {
    color: v.$green;
    @include m.contenedor;

    &__info{

        margin-top: 2rem;
        line-height: 2;
        &--1{

        }
        &--2{

        }
    }

    &__present {
        margin:1.5rem 0;
    }

    &__tech {

        @include m.grid(2,1fr);
        &__info {
    
        }

        &--actual {

        }

        &--learn {

        }
    }

    &__list {
        @include m.grid(2,1fr);
        padding: 2rem 10rem;
        &__element {
            list-style: none;
        }
    }

    &__hobbies {
        margin-top: 3rem;
    }
}
