// colors
// background
$black: #000000;
$granite-gray: #5E605Fff;
$eerie-black: #252525ff;
$smoky-black: #121212ff;
$jet: #2D2D2Dff;
$rich-black-fogra-39: #080808ff;

//text
$platinum: #e8e8e8;
$green: #0f0;

// Typography
$fontHeader: 'Permanent Marker', cursive;
$fontBody: 'Montserrat', sans-serif;

// Screen sizes
$mobile: 375px;
$tablet: 600px;
$desktop: 1440px;