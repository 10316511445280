@use 'helpers/variables' as v;
@use 'helpers/mixins' as m;

html {
    font-size: 62.5%;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

body {
    font-size: 1.6rem;
    background: #000;
    height: 100vh;
    width: 100vw;
    font-family: v.$fontBody;
    line-height: 1.5;
    display: block;
}

h1, h2, h3, p {
    margin: 0;
    font-weight: 600;
}
canvas{
  z-index: 0;
  height: 100vh;
  width: 100vw;
}
h1 {
  color: v.$platinum;
  span{
    font-family: monospace;
    color: v.$green;
  }
}
section{
  height: 88.26%;
}

a {
  width: 10rem;
  color: v.$platinum;
  text-decoration: none;
  padding: 2rem 1rem;
  &:hover{
      color:#0f0;
      text-transform: uppercase;
  }
}

fieldset{
  border: none;
}

textarea {
  background-color: v.$black;
  border: none;
}

input{
  border: none;
  background-color: v.$black;
  color: v.$green;
}