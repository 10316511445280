@use 'helpers/variables' as v;
@use 'helpers/mixins' as m;

.failure {
    width: 25rem;
    padding: 1rem 1rem;
    border: 2px solid darken($color: v.$green, $amount: 1);
    background-color: fade-out(v.$green, $amount: 0.75);
    p {
        color:v.$green;
    }
}