@use 'helpers/variables' as v;
@use 'helpers/mixins' as m;

.projects {
    @include m.reset-lista;
    color: v.$green;
    &__container {
        @include m.contenedor;
        margin-top: 3rem;
        display:flex;
        flex-direction: column;
        :nth-child(even){
            align-self:flex-end;
            justify-self: center;
        }
    }
}

