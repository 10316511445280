@use 'helpers/variables' as v;
@use 'helpers/mixins' as m;


.project{
    width: 60rem;
    height: 40rem;
    color: v.$green;
    display: flex;
    margin: 1rem 0;
    height: 10rem;
    background-color: fade-out(v.$green, $amount: 0.75);
    border: 2px solid v.$green;
    transform: skew(20deg);
    overflow: hidden;
    
    &__heading {
        margin-bottom: 5rem;
    }

    &__technologies {
        display: flex;
        &-component {
            margin-right: 1rem;
        }
    }
}