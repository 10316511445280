@use 'helpers/variables' as v;

.hero {
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
    .hero__image {
        height: 40vh;
        border-radius: 50%;
    }
    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
    }
    .typewrite{
        cursor: none;
        color: v.$green;
        text-decoration: none;
    }
}