@use 'helpers/variables' as v;
.hidden {
    display: none;
}

.selected {
    text-transform: uppercase;
    color: v.$green;
}
.fade-in {
    animation: fadeIn ease 6.5s;
    -webkit-animation: fadeIn ease 6.5s;
    -moz-animation: fadeIn ease 6.5s;
    -o-animation: fadeIn ease 6.5s;
    -ms-animation: fadeIn ease 6.5s;
}
@keyframes fadeIn {
0% {
    opacity:0;
}
100% {
    opacity:1;
}
}

@-moz-keyframes fadeIn {
0% {
    opacity:0;
}
100% {
    opacity:1;
}
}

@-webkit-keyframes fadeIn {
0% {
    opacity:0;
}
100% {
    opacity:1;
}
}

@-o-keyframes fadeIn {
0% {
    opacity:0;
}
100% {
    opacity:1;
}
}

@-ms-keyframes fadeIn {
0% {
    opacity:0;
}
100% {
    opacity:1;
}
}