@use 'helpers/variables' as v;

@mixin phone {
    @media (min-width: v.$mobile) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: v.$tablet) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: v.$desktop) {
        @content;
    }
}

@mixin grid ($columns, $spacing) {
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
    gap: $spacing;
}

@mixin reset-lista {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin contenedor {
    width: min(95%, 130rem);
    margin: 0 auto;
}

@mixin contenedor-md {
    width: min(95%, 80rem);
    margin: 0 auto;
}

@mixin contenedor-sm {
    width: min(95%, 60rem);
    margin: 0 auto;
}