@use 'helpers/variables' as v;
@use 'helpers/mixins' as m;

::placeholder {
    color: darken($color: v.$green, $amount: 0.7);
}

.contact {
    justify-content: center;
    text-align: center;
    color: v.$green;
    @include m.contenedor-md;
    img {
        width: 3rem;
    }
    &__text {
        margin: 5rem 0;

    }
    a {
        color: v.$platinum;
        text-decoration: none;
        &:hover{
            color: darken($color: v.$platinum, $amount: 30%);
        }
    }

    &__form {
        background-color: fade-out($color: v.$green, $amount: 0.75);
        border: 2px solid v.$green;
        gap: 2;
    }

    &__fields {
        display: grid;
        grid-template-areas:
            "name name email email"
            "subject subject subject subject"
            "message message message message"
            "message message message message"
        ;

        gap: 1rem;
        margin: 2rem 2rem 0 2rem;
    }

    &__field {
        height: 2rem;

        &--name {
            grid-area: name;
        }

        &--mail {
            grid-area: email;
        }

        &--subject {
            grid-area: subject;
        }

        &--message{
            grid-area: message;
            color: v.$green;
        }

        &--button {
            background-color: v.$black;
            padding: 1rem 3rem;
            cursor: pointer;
            margin-bottom: 1rem;
        }
    }
}



.social-media {
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}