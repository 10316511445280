@use 'helpers/variables' as v;
@use 'helpers/mixins' as m;

.header {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 1rem 3rem;
    margin-top: 1rem;
}
.main-nav {
    display: flex;
    text-align: center;
}